.mortgage-table {
    width: 100%;
    th, td {
        padding: 0.2rem 0.1rem;
        &:first-child {
            width: 60px;
        }
    }
    tbody {
        display: block;
        height: 260px;
        overflow: auto;
    }
    thead, tfoot tr, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    tfoot {
        td {
            padding: 0.5rem 0.2rem;
        }
    }
    &-wrapper {
        padding: 1rem 0;
    }
}