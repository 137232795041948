@import "../_variables.scss";
[class*='col-'] {
    @media only screen and (max-width: 600px) {
        padding-right: 5px;
        padding-left: 5px;
    }
}
.btn {
    font-family: $primary-font;
    font-size: .875rem;
    line-height: 1.5;
    font-weight: $normal-weight;
}
.form-group {
    font-family: $primary-font;
    font-size: .875rem;
    line-height: 1.5;
    font-weight: $normal-weight;
    margin-bottom: 0.75rem;
    .form-label {
        font-size: inherit;
        line-height: inherit;
        margin-bottom: 0.25rem;
    }
    .form-control {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
    }
    .input-group {
        &-text {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
        }
        .input-group-prepend, .input-group-append {
            .input-group-text {
                background-color: $white;
            }
        }
    }
}
.rbt-token {
    color: $white !important;
    background: $green-cyan !important;
}