$primary-font: 'Poppins', sans-serif;

$light-weight: 300;
$normal-weight: 400;
$medium-weight: 500;
$semi-bold-weight: 600;
$bold-weight: 700;

$light-gray: #CCCCCC;
$light-grayish-blue: #f6f7fc;
$light-grayish-blue-1: #ced4da;
$green-cyan: #22ab84; 
$strong-cyan: #17744d;
$dark-gray: #333333;
$black: #000000;
$white: #FFFFFF;
$light-red: #ff4a55;
$base-color: $dark-gray;

$theme-colors: ( 'light-grayish-blue': $light-grayish-blue, 'green-cyan': $green-cyan, 'strong-cyan': $strong-cyan, 'dark-gray': $dark-gray, 'white': $white, 'primary': $green-cyan ) !default;