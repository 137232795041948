@import "./_variables.scss";
@import "./_typography.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./bootstrap/_customizing.scss";
:root {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ced4da;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #666;
    }
    body {
        font-family: $primary-font;
        font-size: .875rem;
        line-height: 1.5;
        font-weight: $normal-weight;
        background: $white;
        color: $base-color;
    }
    section {
        padding: 25px 0;
    }
    .btn-action {
        padding: 5px 20px;
        background-color: $green-cyan;
        color: $white;
        font-weight: $medium-weight;
        border-radius: 4px;
        &.selected {
            background: $strong-cyan;
            border-color: $strong-cyan;
            text-decoration: underline;
        }
    }
    .btn-delete {
        padding: 5px 20px;
        background-color: $light-red;
        border-color: $light-red;
        color: $white;
        font-weight: $medium-weight;
        border-radius: 4px;
    }
    .btn-apply {
        padding: 5px 20px;
        background-color: $green-cyan;
        color: $white;
        font-weight: $medium-weight;
        border-radius: 4px;
        margin-bottom: 1rem;
    }
    .btn-optimize {
        background-color: $light-red;
        border-color: $light-red;
        padding: 10px 40px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 0.175rem;
        &:hover {
            background-color: $light-red;
            border-color: $light-red;
        }
        &:active {
            box-shadow: 1px 1px $light-red !important;
            background-color: $light-red !important;
            border-color: $light-red !important;
        }
    }
    .btn-loan-purpose {
        cursor: pointer;
        width: 100%;
        padding: 10px 20px;
        background-color: $white;
        color: $base-color;
        font-weight: $medium-weight;
        border: solid 1px $black;
        border-radius: 0.25rem;
        svg {
            width: 60px;
            height: auto;
            margin-bottom: 0.5rem;
        }
        h6 {
            margin-bottom: 0;
        }
        @media only screen and (max-width: 600px) {
            padding: 5px;
        }
        &.selected {
            background: $green-cyan;
            color: $white;
            border: solid 1px $green-cyan;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
            svg {
                fill: $white;
            }
            h6 {
                color: $white;
            }
        }
        &:hover {
            background: $green-cyan;
            color: $white;
            border: solid 1px $green-cyan;
            box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
            h6 {
                color: $white;
            }
            svg {
                fill: $white;
            }
        }
    }
    .btn-get-quote {
        &.submitted {
            background: $light-red;
            border-color: $light-red;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .btn-loan-assistant {
        width: 100%;
        @media only screen and (max-width: 600px) {
            padding: 5px;
        }
        &:hover {
            background: $strong-cyan;
            border-color: $strong-cyan;
            text-decoration: underline;
        }
    }
    .custom-checkbox {
        font-size: inherit;
        display: block;
        position: relative;
        padding: 0 0 0 2rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:hover input ~ .checkmark {
            background-color: $light-grayish-blue-1;
        }
        &.inline {
            display: inline-block;
            margin-right: 1.5rem;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                background-color: $strong-cyan;
                &:after {
                    display: block;
                }
            }
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border: solid 1px $light-grayish-blue-1;
            border-radius: 0.25rem;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: 1px solid $white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    .custom-radio {
        &.inline {
            display: inline-block;
            margin-right: 1.5rem;
        }
    }
    .expand-title {
        cursor: pointer;
        text-decoration: underline;
        // border-bottom: solid 1px $base-color;
        // position: relative;
        // top: 0;
        // display: inline-block;
        margin-bottom: 10px;
    }
    .expand-icon, .collapse-icon {
        background: $base-color;
        font-size: 1.25rem;
        color: $white;
        width: 0.8em;
        height: 0.8em;
        margin-left: 5px;
    }
    ul {
        list-style: none;
        &.results {
            padding-left: 0;
            li {
                font-weight: 500;
                margin-top: 0.5rem;
            }
        }
    }
    .indent-box {
        border: solid 1px $light-grayish-blue-1;
        border-radius: 0.1rem;
        margin-bottom: 1rem;
        padding: 1rem;
        box-shadow: 2px 2px $light-gray;
        &.result {
            background-color: $light-grayish-blue;
        }
    }
    .required-text {
        color: $light-red;
    }
}