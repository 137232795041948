.content-wrapper {
    background: url('../../../assets/images/bg.png') no-repeat;
    height: calc(100vh - 60px);
    &.home {
        background: none;
    }
    .page-title {
        border-bottom: solid 1px #17744d;
        color: #17744d;
        text-transform: uppercase;
        position: relative;
        top: 0;
        display: inline-block;
        margin-bottom: 15px;
    }
}
.badge {
    padding: 2px 10px;
    color: white;
    border-radius: 0.25rem;
    font-size: 100%;
    &-green {
        background: #17744d;
        border: 1px solid #17744d;
    }
    &-red {
        background: #ff4a55;
        border: 1px solid #ff4a55;
    }
}