.signup-wrapper {
    background-color: #FFF;
    width: 35%;
    padding: 30px;
    @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 30px 10px;
    }
    @media only screen 
    and (min-device-width : 601px) 
    and (max-device-width : 1024px) {
        width: 50%;
    }
    .title {
        font-weight: 300;
        text-align: center;
        margin-bottom: 25px;
    }
}