.admin-layout {
    min-height: 100vh;
    .admin-container {
      min-height: calc(100vh - 81px);
      .sidebar-wrapper {
        background: #FFF;
        height: 100%;
        box-shadow: 5px 0 5px -5px rgba(9,57,89,.12);
        padding: 24px 10px;
        margin-top: 1px;
      }
      .main-wrapper {
        padding: 24px;
        .btn {
          &.btn-action {
            background-color: #22ab84 !important;
            padding: 5px !important;
            border-radius: 4px !important;
            margin: 0 2px !important;
            svg {
              width: 0.825em !important;
              height: 0.825em !important;
            }
          }
        }
      }
    }
 }