.mortgage-chart {
    .baseline {
        stroke-dasharray: 4,4;
    }
    .line {
        fill: none;
        stroke: #333333;
        stroke-width: 1.5px;
    }
    &-wrapper {
        padding: 1rem 0;
    }
}