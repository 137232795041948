@import "./_variables.scss";
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $primary-font !important;
    color: $base-color;
    font-weight: $medium-weight;
}

h6 {
    font-size: 1rem !important;
    line-height: 1.3rem !important;
    letter-spacing: 0.2px !important;
}

h5 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    letter-spacing: 0.2px !important;
}

h4 {
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    letter-spacing: 0.2px !important;
}

h3 {
    font-size: 2.25rem !important;
    line-height: 3.15rem !important;
    letter-spacing: 0.2px !important;
}

h2 {
    font-size: 2.625rem !important;
    line-height: 3.675rem !important;
    letter-spacing: 0.2px !important;
}

h1 {
    font-size: 2.875rem !important;
    line-height: 4.025rem !important;
    letter-spacing: 0.2px !important;
}