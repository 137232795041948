.authentication {
  &-layout {
    min-height: 100vh;
    background: #f6f7fc;
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 81px);
  }
}


// .authentication-layout {
//     min-height: 100vh;
//     background: #f6f7fc;
//     .authentication-container {
//       display: flex;
//       flex-wrap: wrap;
//       align-items: center;
//       justify-content: center;
//       width: 100%;
//       min-height: calc(100vh - 81px);
//       .main-wrapper {
//         padding: 30px;
//         background-color: #FFF;
//         .form-title {
//           font-weight: 300;
//           text-align: center;
//           margin-bottom: 25px;
//         }
//         .link-wrapper {
//           padding-top: 10px;
//           list-style: none;
//           .link {
//             display: inline-block;
//             padding: 0 5px;
//           }
//         }
//         .logo {
//           width: 20%;
//           display: block;
//           margin-left: auto;
//           margin-right: auto;
//           padding-bottom: 20px;
//         }
//       }
//     }
//     a {
//       color: inherit;
//     }
//   }